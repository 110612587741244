import { VApplication } from '@/application';
import { LoggerModule } from '@vlinder-web/logger-module-react';
import { SupplyChainTemplatePageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.addGroup';
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.SupplyChainTemplatePageService'
  ) as SupplyChainTemplatePageService;
};

export const addGroup = async (context: any, event: any) => {
  const currentState = 'ADD_CREDIT_UNION';
  const method = 'addGroup';

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.addGroup(event?.payload);
      console.log('addGroup content =>', content);
      if (content) {
        return resolve({
          key: 'ADD_CREDIT_UNION_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'add Credit union failed';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'ADD_CREDIT_UNION_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'ADD_CREDIT_UNION_FAILED',
        value: _msg,
      });
    }
  });
};
