import { VApplication } from '@/application';
import { LoggerModule } from '@vlinder-web/logger-module-react';
import { AMFileDetailService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.getPage';
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync('services.AMFileDetailService') as AMFileDetailService;
};

export const revokeCredential = async (context: any, event: any) => {
  const currentState = 'REVOKE_CREDENTIAL';
  const method = 'revokeCred';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.revokeCred(event?.payload);

      if (content) {
        return resolve({
          key: 'REVOKE_CREDENTIAL_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'REVOKE_CREDENTIAL_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      return reject({
        key: 'REVOKE_CREDENTIAL_FAILED',
        value: err?.message,
      });
    }
  });
};
