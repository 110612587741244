//@ts-nocheck
import React from 'react';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  BackspaceIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

import { toHeaderCase } from 'js-convert-case';
import { ToggleAction } from './ToggleAction';

import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import * as S from './styles';
import { constantCase } from 'change-case';
const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

const getFirstLetterOfEachWord = (text) =>
  text && constantCase(text)?.match(/\b\w/g).join('');

export const DomainTableRow = (props) => {
  const {
    id,
    rowData,
    vid,
    verified,
    status,
    email,
    dateCreated,
    isSelected,
    selectUser,
    onRowClick,
    icon,
    amFileName,
    partNo,
    printerId,
    printerName,
    date,
    blockChainProof,
  } = props;
  console.log('date created =>', dateCreated);
  const VerifiedIcon = verified ? CheckCircleIcon : InformationCircleIcon;
  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'inactive'
      ? 'warning'
      : status === 'pending'
      ? 'purple'
      : status === 'revoked'
      ? 'danger'
      : 'primary';
  const onToggle = (key: string) => {
    console.log(`toggle in row`, key, email);
    if (key === 'view') {
      //onViewClick && onViewClick({ ...props });
    } else {
      onTogglePress && onTogglePress(key, email);
    }
  };
  const onUserChecked = (event: any, id: string) => {
    selectUser && selectUser(id);
    event.stopPropagation();
  };

  return (
    <tr className="border-bottom">
      <td>
        <FormCheck type="checkbox" className="dashboard-check">
          <FormCheck.Input
            id={`user-${id}`}
            checked={isSelected}
            onClick={(e) => onUserChecked(e, id)}
          />
          <FormCheck.Label htmlFor={`user-${id}`} />
        </FormCheck>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          {icon ? (
            <Image src={icon} className="avatar rounded-circle me-3" />
          ) : (
            <div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
              <span>{amFileName && getFirstLetterOfEachWord(amFileName)}</span>
            </div>
          )}
          <div className="d-block">
            <span className="fw-bold">{amFileName}</span>
          </div>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{partNo}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{printerId}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{printerName}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <span className="fw-normal">{date}</span>
        </Card.Link>
      </td>
      <td>
        <Card.Link className="d-flex align-items-center">
          <a
            href="https://mumbai.polygonscan.com/tx/0xd42d806d2dc78369bd45068783f0fcf7a965f4b046cc5ca0a79ec8d7a2225bdd"
            target="_blank"
            className="fw-normal"
          >
            {blockChainProof}
          </a>
          {/* <span className="fw-normal">{blockChainProof}</span> */}
        </Card.Link>
      </td>
    </tr>
  );
};

DomainTableRow.defaultProps = {
  viewTitle: 'View Certificate',
};
