import { VApplication } from '@/application';
import { OrgComplianceListPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.getPage';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.OrgComplianceListPageService'
  ) as OrgComplianceListPageService;
};

export const createOrgCompliance = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'createOrgCompliance';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.createOrgCompliance(event?.payload);
      if (content) {
        return resolve({
          key: 'CREATE_ORG_COMPLIANCE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Error in creating org compliance';
        return reject({
          key: 'CREATE_ORG_COMPLIANCE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'CREATE_ORG_COMPLIANCE_ERROR',
        value: _msg,
      });
    }
  });
};
