import { VApplication } from '../../../../../application';
import { OrganizationPageService } from '../../../services';

const MODULE_NAME = 'OrganizationPage.machines.states.getPage';

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync(
    'services.OrganizationPageService'
  ) as OrganizationPageService;
};

export const getPageContent = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getPageContent';

  return new Promise(async (resolve, reject) => {
    try {
      const srv = getSrv();
      const content = await srv.getPageContent({ type: event.type });

      if (content) {
        return resolve({
          key: 'RENDER_PAGE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        return reject({
          key: 'RENDER_PAGE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'RENDER_PAGE_ERROR',
        value: _msg,
      });
    }
  });
};
